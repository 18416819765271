<script>
import appConfig from '@src/app.config'
import Layout from '@layouts/main'
import axios from '@/axios'
import CreateVideo from '@components/video/createvideo'
import CreateSub from '@components/subcategory/createsub'
import CreateSubinSub from '@components/subcategory/createsubinsub'

// import PageHeader from '@components/page-header'

/**
 * Contents component
 */
export default {
  page: {
    title: 'Create',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: {
    Layout,
    CreateVideo,
    CreateSub,
    CreateSubinSub,
    // PageHeader
  },
  data() {
    return {
      data: {},
      is_sub: null,
      is_subcategory: null,
    }
  },
  mounted() {
    this.onLode()
  },
  methods: {
    async onLode() {
      await axios
        .get(`/api/category/${this.$route.params.id}`)
        .then((res) => {
          this.data = res.data
        })
        .catch((err) => {
          alert(err.response.data.message)
        })
    },
  },
}
</script>

<template>
  <Layout>
    <div class="row page-title align-items-center">
      <div class="col-md-3 col-xl-6">
        <h4 class="mb-1 mt-0"></h4>
      </div>
      <div
        v-if="data.is_subcategory != true && data.is_sub === undefined"
        class="col-xl-6"
      >
        <!-- <button
          class="btn btn-primary rounded-pill width-lg float-right aligncenter ml-2 mr-2"
          @click="
            is_subcategory = true
            is_sub = null
          "
        >
          <feather type="file-plus"></feather>&nbsp;Create Child Subcategory
        </button>-->

        <button
          class="btn btn-primary rounded-pill width-lg float-right aligncenter ml-2 mr-2"
          @click="
            () => {
              this.is_sub = true
              is_subcategory = false
            }
          "
        >
          <feather type="file-plus"></feather>Create Subcategory
        </button>

        <button
          class="btn btn-primary rounded-pill width-lg float-right aligncenter ml-2 mr-2"
          @click="
            () => {
              this.is_sub = false
            }
          "
        >
          <feather type="image"></feather>&nbsp;Create Video
        </button>
      </div>

      <div class="row mt-2" style="height:100%; width:100%;">
        <div class="col-12">
          <div class="card">
            <div
              v-if="data.is_sub !== undefined || is_sub !== null"
              class="card-body"
            >
              <CreateVideo
                v-if="data.is_sub == false || is_sub == false"
                :category_code="data._id"
              ></CreateVideo>

              <CreateSub
                v-if="data.is_sub == true || is_sub == true"
                :category_code="data.category_code"
              ></CreateSub>
            </div>

            <!-- <div
              v-if="data.is_subcategory == true || is_subcategory == true"
              class="card-body"
            >
              <CreateSubinSub
                v-if="data.is_subcategory || is_subcategory == true"
                :categorycode="data.category_code"
              ></CreateSubinSub>
            </div>-->
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
